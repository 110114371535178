import React from 'react';
import './Exploration.css';
import Footer from '../CustomFooter/CustomFooter'
import FooterBar from '../CustomFooterBar/CustomFooterBar'
import GoogleMap from './GoogleMap'
import { Marker } from 'google-maps-react';
import Config from '../Config';

class Exploration extends React.Component {

  constructor(props) {
    super(props);

    this.setUpMarkers = this.setUpMarkers.bind(this);
    this.setUpCenter = this.setUpCenter.bind(this);


    this.state = {
      allCoordinates: [],
    };
  }

  componentWillMount () {
    fetch(Config() + "/api/get_coordinates/")
      .then(res => res.json())
      .then(items => {
        this.setState({allCoordinates: items.data});
    });
  }

  setUpMarkers() {
    let markers = []

    for (var i = 0; i < this.state.allCoordinates.length; i++) {
      let mark = this.state.allCoordinates[i];
      if (!mark.central) {
        markers.push(
          <Marker key={i} id={i} position={{
            lat: parseFloat(mark.latitude),
            lng: parseFloat(mark.longitude)
          }} />
        );
      }
    }

    return markers;
  }

  setUpCenter() {
    let center = {}

    for (var i = 0; i < this.state.allCoordinates.length; i++) {
      let mark = this.state.allCoordinates[i];
      if (mark.central) {
        center.lat = parseFloat(mark.latitude);
        center.lng = parseFloat(mark.longitude);
        break;
      }
    }

    return center;
  }


  render() {
    return (
      <div>
        <section className="landing">
          <div className="container">
            <div className="row pt-5">
              <div className="col-12 pt-5 text-center">
                <h1 className="white-color">LETS GO EXPLORE</h1>
                <p className="white-color">in 3 easy steps</p>
              </div>
            </div>
            <div className="row hide-mobile">
              <div className="col-12">
                <img src={require('./resources/LAUNCH.png')} alt="" width="100%" height="100%" />
              </div>
            </div>
            <div className="row show-mobile">
              <div className="col-12">
                <img src={require('./resources/UFO.png')} alt="" width="100%" height="100%" />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 google pt-3 pb-3">
                <img className="stores" src={require('./resources/GOOGLE_STORE.png')} alt="" />
              </div>
              <div className="col-sm-6 apple pt-3 pb-3">
                <img className="stores" src={require('./resources/APPLE_STORE.png')} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="dark-bg pattern-bg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <img className="about-image-width" src={require('./resources/SOLAR_ONE.png')} alt=""/>
              </div>  
            </div>
            <div className="row pb-2">
              <div className="col-12 text-center">
                <h1 className="white-color">LET’S GO GREEN</h1>
              </div>  
            </div>
            <div className="row pb-5">
              <div className="col-12 text-center">
                <p className="white-color">Powered by Solar,  Asambe Stations are where you can launch and land a 
                  vehicle as well as a place to swap/charge vehicle batteries.</p>
              </div>  
            </div>
          </div>
        </section>

        <section>
          <div id="map">
            <GoogleMap center={this.setUpCenter()} markers={this.setUpMarkers()} />
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row text-center pt-5 pb-5">
              <div className="col-md-12">
                <h1 className="light-purple-color">OUR RIDES</h1>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-md-6">
                <img src={require('./resources/E-SCOOTER.png')} alt="" width="100%" />
                <div className="row text-center pb-2 pt-2">
                  <div className="col-12">
                    <h3 className="purple-color">ELECTRIC SCOOTER</h3>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-sm-6">
                    <img src={require('./resources/SPEEDOMETER.png')} alt="" width="20%" height="50" />
                    <p className="dark-purple-color"><b>Max Speed</b></p>
                    <p className="dark-purple-color"><b>65 km/h</b></p>
                  </div>
                  <div className="col-sm-6">
                    <img src={require('./resources/POWER.png')} alt="" width="20%" height="50" />
                    <p className="dark-purple-color"><b>Range</b></p>
                    <p className="dark-purple-color"><b>70 km</b></p>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-md-12">
                    <p className="dark-purple-color">Requires drivers licence</p>
                    <p className="dark-purple-color"><b>Price: 0.99c + $4.99 per hr</b></p>
                  </div>
                </div>  
              </div>
              <div className="col-md-6">
                <img src={require('./resources/E-BIKE.png')} alt="" width="100%" />                
                <div className="row text-center pb-2 pt-2">
                  <div className="col-12">
                    <h3 className="purple-color">ELECTRIC BICYCLE</h3>
                  </div>
                </div>  
                <div className="row text-center">
                  <div className="col-sm-6">
                    <img src={require('./resources/SPEEDOMETER.png')} alt="" width="20%" height="50" />
                    <p className="dark-purple-color"><b>Max Speed</b></p>
                    <p className="dark-purple-color"><b>40 km/h</b></p>
                  </div>
                  <div className="col-sm-6">
                    <img src={require('./resources/POWER.png')} alt="" width="20%" height="50" />
                    <p className="dark-purple-color"><b>Range</b></p>
                    <p className="dark-purple-color"><b>60 km</b></p>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-md-12">
                    <p className="dark-purple-color">Does not require drivers licence</p>
                    <p className="dark-purple-color"><b>Price: 0.99c + $2.99 per hr</b></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pattern-bg">
          <div className="container text-center">
            <div className="row pt-5">
              <div className="col-md-6">
                <img src={require('./resources/PHONE_MOCKUP.png')} alt="" width="100%" height="100%" />
              </div>
              <div className="col-md-6">
                <div className="row mt-5">
                  <div className="col-md-12">
                    <h1 className="white-color">LET'S GO</h1>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="white-color">TOGETHER.</h1>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <p className="white-color">Experience Asambe <b className="dark-purple-color">TODAY</b>. 
                      Asambe works on all major platforms, 
                      enabling you to seamlessly explore.</p>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-12">
                    <img className="stores" src={require('./resources/GOOGLE_STORE.png')} alt="" />
                  </div>
                </div> 
                <div className="row pt-3 pb-5">
                  <div className="col-md-12">
                    <img className="stores" src={require('./resources/APPLE_STORE.png')} alt="" />
                  </div>
                </div>    
              </div>
            </div>
          </div>  
        </section>

        <section className="guided">
          <div className="container text-center">
            <div className="row pt-5 pb-5">
              <div className="col-12">
                <h1 className="white-color">TRY A GUIDED EXPERIENCE</h1>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">  
                <a href="/guide" type="button" className="btn btn-light-purple white-color pr-4 pl-4">Enter</a>
              </div>  
            </div>
          </div>
        </section>

        <Footer />

        <FooterBar />
      </div>
    );
  }
}
export default Exploration;