import React from 'react';
import './Logistics.css';
import Footer from '../CustomFooter/CustomFooter';
import FooterBar from '../CustomFooterBar/CustomFooterBar';
import { Link } from "react-router-dom";

class About extends React.Component {
  
  render() {
    return (
      <div>

        <section className="logistic-bg">
          <div className="text-center container pt-5 pb-5">
              <div className="row row-logistics">
                  <div className="col-md-6 bottom">
                    <div className="card logistic-card bg-transparent text-white">
                        <div className="card-body">
                            <div className="row pt-2">
                                <div className="col">
                                    <h1 className="card-title">WELCOME TO <br/> THE FUTURE <br/> OF AFRICAN</h1>
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col">
                                <h1 className="card-title color-alt">LOGISTICS</h1>
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col">
                                <p className="card-text">Asambe Logistics leverages electric bicycles, smart logistics trailers and its ecommerce platform to provide end-to-end logistics services to growing SMEs and sustainable brands</p>
                                </div>
                            </div>    
                            <div className="row pt-2 pb-2">
                                <div className="col"></div>
                                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 pt-2">
                                    <a href="javascript:void(Tawk_API.toggle())" className="btn btn-block button-style-1 mr-2 mt-2">CONTACT</a>
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6 top">
                    <img className="logistic-landing" src={require('./resources/GUIDED_EXP.png')} alt="" />
                  </div>
              </div>
          </div>
        </section>
        <section className="logistic-bg">
          <div className="text-center container pt-5 pb-2">
              <div className="row row-logistics">
                  <div className="col-md-6">
                    <div className="card bg-transparent text-white">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h1 className="card-title">OUR</h1>
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col">
                                <h1 className="card-title color-alt">E-BIKES</h1>
                                </div>
                            </div> 
                            <div className="row pt-2">
                                <div className="col">
                                <p className="card-text">Asambe offers 3rd party last mile electric bicycle deliveries with the goal of being mode the of choice for all deliveries below 15 km distance and packages below 30kgs</p>
                                </div>
                            </div>    
                            <div className="row pt-2">
                                <div className="col"></div>
                                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 pt-2">
                                    <a href="javascript:void(Tawk_API.toggle())" className="btn btn-block button-style-1 mr-2 mt-2">CONTACT</a>
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img className="logistic-landing" src={require('./resources/BIKE.png')} alt="" />
                  </div>
              </div>
              <div className="row pt-5 pb-2">
                <div className="col">
                    <h1 className="color-white">TRUSTED BY</h1>
                </div>
              </div>
          </div>
        </section>
        <section >
          <div className="container text-center pt-1 pb-1">
              <div className="row row-logistics">
                  <div className="col-md-4">
                    <img className="slide-img" src={require('./resources/FedEx.png')} alt="" />
                  </div>
                  <div className="col-md-4">
                    <img className="slide-img" src={require('./resources/baines.png')} alt="" />
                  </div>
                  <div className="col-md-4">
                    <img className="slide-img" src={require('./resources/kuva.png')} alt="" />
                  </div>
              </div>
          </div>
        </section>
        <section className="logistic-bg">
          <div className="text-center container pt-5 pb-5">
              <div className="row row-logistics">
                  <div className="col-md-6">
                    <div className="card bg-transparent text-white">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h1 className="card-title">OUR</h1>
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col">
                                <h1 className="card-title color-alt">SMART LOGISTICS TRAILER</h1>
                                </div>
                            </div> 
                            <div className="row pt-2">
                                <div className="col">
                                    <p className="card-text">Asambe has developed a solar powered, refrigerated goods trailer fitted with an IoT device for tracking and remote locking. These smart logistics trailers (SLTs) are transported by our network of independent minibus taxi operators (kombi/matatu) with daily service across our countries of operation. </p>
                                    <p className="card-text"> SLTs connect rural shops to urban wholesalers, rural customers to ecommerce through the use of rural shops as ecommerce agents (order aggregators) and rural farmers to urban wholesalers.</p>
                                </div>
                            </div>    
                            <div className="row pt-2">
                                <div className="col"></div>
                                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 pt-2">
                                    <a href="javascript:void(Tawk_API.toggle())" className="btn btn-block button-style-1 mr-2 mt-2">CONTACT</a>
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img className="logistic-landing glow" src={require('./resources/SOLAR.png')} alt="" />
                  </div>
              </div>
          </div>
        </section>
        <section className="logistic-bg">
          <div className="text-center container pt-5 pb-5">
              <div className="row row-logistics">
                  <div className="col-md-6">
                    <div className="card bg-transparent text-white">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h1 className="card-title">OUR</h1>
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col">
                                <h1 className="card-title color-alt">E-COMMERCE PLATFORM</h1>
                                </div>
                            </div> 
                            <div className="row pt-2">
                                <div className="col">
                                    <p className="card-text">Asambe Shop is an ecommerce platform targeted at growing SMEs and sustainable brands. The platform currently hosts a wide array of exciting merchants across a wide range of product offerings.  Warehouse and shared kitchen space is available to merchants at competitive rates.</p>
                                    <p className="card-text">Deliveries are done exclusively through electric bicycles and electric hybrid vehicles</p>
                                </div>
                            </div>    
                            <div className="row pt-2">
                                <div className="col"></div>
                                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 pt-2">
                                    <a href="https://www.asambe.shop/" className="btn btn-block button-style-1 mr-2 mt-2">SHOP</a>
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img className="logistic-phone" src={require('./resources/phones.png')} alt="" />
                  </div>
              </div>
          </div>
        </section>
        <section className="logistic-bg-alt">
          <div className="text-center container pt-5 pb-5">
              <div className="row row-logistics">
                  <div className="col">
                    <div className="card bg-transparent text-white">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h1 className="card-title">LET'S GO</h1>
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col">
                                <h1 className="card-title color-alt-1">TOGETHER</h1>
                                </div>
                            </div> 
                            <div className="row pt-2">
                                <div className="col">
                                    <p className="card-text">Discover what lies beyond. Contact us today to join the African logistics revolution</p>
                                </div>
                            </div>    
                            <div className="row pt-2">
                                <div className="col"></div>
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-2">
                                    <a href="javascript:void(Tawk_API.toggle())" className="btn btn-block button-style-2 mr-2 mt-2">CONTACT</a>
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
        </section>

        <Footer />

        <FooterBar />

      </div>  
    );
  }
}
export default About;