import firebase from 'firebase'

var firebaseConfig = {
  apiKey: "AIzaSyCVlkF3BGtCWa1D1qpBqCdThxvQOHvQOM8",
  authDomain: "asambe-2020.firebaseapp.com",
  databaseURL: "https://asambe-2020.firebaseio.com",
  projectId: "asambe-2020",
  storageBucket: "asambe-2020.appspot.com",
  messagingSenderId: "200396730574",
  appId: "1:200396730574:web:0a3ee03d716d039d4ea09f",
  measurementId: "G-5EL5H82HSR"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
