import React from 'react';
import './CustomFooter.css';
import { Link } from "react-router-dom";

const facebook = "https://www.facebook.com/Asambe-103687158046153/";
const instagram = "https://www.instagram.com/asambe.africa/";
const tripadvisor = "https://www.tripadvisor.co.za/Attraction_Review-g293761-d20981474-Reviews-Asambe_Experiences-Victoria_Falls_Matabeleland_North_Province.html";
const twitter = "https://twitter.com/AfricaAsambe";

function CustomFooter() {

  return (
    <section className="footer-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-4 text-center">
            <img src={require('./resources/FOOTER_LOGO_CROPPED.png')} width="70%" alt="" />
          </div>
          <div className="col-md-4">
            <div className="container pt-1 text-center">
              <div className="row">
                <div className="col-12">
                  <h3 className="white-color">DISCOVER</h3>
                </div>
              </div>
              <div className="row pt-1">
                <div className="col-12">
                  <Link className="btn btn-block button-style-2 mr-2 mt-2" to="/about#about">What’s Asambe About</Link>
                  </div>
              </div>    
              <div className="row pt-1">
                <div className="col-12">
                  <Link className="btn btn-block button-style-2 mr-2 mt-2" to="/about#brand">What’s The Brand About</Link>
                </div>
              </div>
              <div className="row pt-1">
                <div className="col-12">
                  <Link className="btn btn-block button-style-2 mr-2 mt-2" to="/about#together">The Together Initiative</Link>
                </div>
              </div>
              <div className="row pt-1">
                <div className="col-12">
                  <Link className="btn btn-block button-style-2 mr-2 mt-2" to="/about#support">Support and Partners</Link>
                </div>
              </div>    
              <div className="row pt-1">
                <div className="col-12">
                  <Link className="btn btn-block button-style-2 mr-2 mt-2" to="/contact">Contact Us</Link>
                </div>
              </div>        
            </div>
          </div>
          <div className="col-md-4">
            <div className="container footer-height">
              <div className="row align-items-center footer-height pt-5 pb-5">
                <div className="col-12 text-center">
                  <a href={facebook} target="_blank" rel="noopener noreferrer"><img className="pr-3" src={require('./resources/FACEBOOK.png')} alt="" /></a>
                  <a href={instagram} target="_blank" rel="noopener noreferrer"><img className="pr-3 pl-3" src={require('./resources/IG.png')} alt="" /></a>
                  <a href={twitter} target="_blank" rel="noopener noreferrer"><img className="pr-3 pl-3" src={require('./resources/TWITTER.png')} alt="" /></a>
                  <a href={tripadvisor} target="_blank" rel="noopener noreferrer"><img className="pl-3" src={require('./resources/TRIP_ADVISOR.png')} alt="" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )   
}

export default CustomFooter;