import React from 'react';
import './Guide.css';
import Astronaut from './Astronaut'
import Footer from '../CustomFooter/CustomFooter'
import FooterBar from '../CustomFooterBar/CustomFooterBar'
import Config from '../Config'

class Guide extends React.Component {
  
  constructor(props) {
    super(props);

    this.showDiv = this.showDiv.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openGuideView = this.openGuideView.bind(this);
    this.displayImage = this.displayImage.bind(this);
    this.state = {
      tours: [],
      locations: [],
      tourTypes: [],
      vehicleTypes: [],
      search: {
        location: '',
        activity_duration: '',
        tour_type: '',
        vehicle_type: '',
      },
      showAdvanced: false,
      images: [],
    };
  }

  componentDidMount() {
    // fetch(Config() + "/api/get_activity/")
    //   .then(res => res.json())
    //   .then(items => {
    //     var location = items.data.filter(e =>  e.location).map(e => e.location);
    //     var tourType = items.data.filter(e =>  e.tour_type).map(e => e.tour_type);
    //     var vehicleType = items.data.filter(e =>  e.vehicle_type).map(e => e.vehicle_type);
    //     location = Array.from(new Set(location));
    //     tourType = Array.from(new Set(tourType));
    //     vehicleType = Array.from(new Set(vehicleType));
    //     this.setState({tours: items.data});
    //     this.setState({tourTypes: tourType});
    //     this.setState({vehicleTypes: vehicleType});
    //     this.setState({locations: location});
    //   });

    var items = {
      data: [{
        pk: 1,
        title: "Mukuvisi Woodlands Afternoon Electric Bicycle Tour and Sunset Picnic",
        rating: 5,
        description: "Asambe presents an exciting opportunity to escape the city and explore the hidden gem that is Mukuvisi Woodlands. Your inner city Safari experience. There is no experience needed, electric bicycles make cycling easy and accessible to everyone. Come and enjoy a relaxing cycle through woodlands topped off with a beautiful sunset picnic in the park presented by The Grub & Wine Society. Our guides are professional and on hand to share insights on the woodlands, its wildlife and its fauna and flora.",
        price: 30,
        location: "Harare",
        vehicle_type: "Electric Bicycle",
        tour_type: "Adventure, Culinary",
        linkk: "https://www.tripadvisor.co.za/AttractionProductReview-g293760-d21018376-Mukuvisi_Woodlands_Afternoon_Electric_Bicycle_Tour_and_Sunset_Picnic-Harare_Harare.html",
          
      }]
    }

    var location = items.data.filter(e =>  e.location).map(e => e.location);
    var tourType = items.data.filter(e =>  e.tour_type).map(e => e.tour_type);
    var vehicleType = items.data.filter(e =>  e.vehicle_type).map(e => e.vehicle_type);
    location = Array.from(new Set(location));
    tourType = Array.from(new Set(tourType));
    vehicleType = Array.from(new Set(vehicleType));
    this.setState({tours: items.data});
    this.setState({tourTypes: tourType});
    this.setState({vehicleTypes: vehicleType});
    this.setState({locations: location});

    //   fetch(Config() + "/api/get_images/")
    // .then(res => res.json())
    // .then(items => {
    //   this.setState({images: items.data});
    // });

    var images = [
      {
        activity: 1,
        img_url: "https://firebasestorage.googleapis.com/v0/b/asambe-2020.appspot.com/o/images%2FM-IMG_2984.jpg?alt=media&token=57ef784c-1979-4e9b-9dde-832b3b984ac3",
      },
      {
        activity: 1,
        img_url: "https://firebasestorage.googleapis.com/v0/b/asambe-2020.appspot.com/o/images%2Fimageline-14.jpg?alt=media&token=287f011b-8801-4c67-91a9-ef83d73140b3",
      },
      {
        activity: 1,
        img_url: "https://firebasestorage.googleapis.com/v0/b/asambe-2020.appspot.com/o/images%2FM-IMG_2941.jpg?alt=media&token=b37d2086-c0a7-4589-9f46-07b29ac50522",
      },
      {
        activity: 1,
        img_url: "https://firebasestorage.googleapis.com/v0/b/asambe-2020.appspot.com/o/images%2FM-LRM_EXPORT_9540734267335_20190625_212407094-1-1500x1000.jpeg?alt=media&token=d02b52e8-cf56-4f14-a383-451891c97804",
      },
      {
        activity: 1,
        img_url: "https://firebasestorage.googleapis.com/v0/b/asambe-2020.appspot.com/o/images%2FIMG_2951..jpg?alt=media&token=46114455-0806-4d3e-891b-5ccb157f55ce",
      },
      {
        activity: 1,
        img_url: "https://firebasestorage.googleapis.com/v0/b/asambe-2020.appspot.com/o/images%2Fimageline-2.jpg?alt=media&token=16d17d09-0e99-4883-b6aa-aa4b2c2085aa",
      },
      {
        activity: 1,
        img_url: "https://firebasestorage.googleapis.com/v0/b/asambe-2020.appspot.com/o/images%2Fimageline-13.jpg?alt=media&token=a8269c78-8ac0-4816-9410-e1be68c070ed",
      },
      {
        activity: 1,
        img_url: "https://firebasestorage.googleapis.com/v0/b/asambe-2020.appspot.com/o/images%2Fimageline-5.jpg?alt=media&token=c601a9e9-27bd-4c36-a6d1-09e5de8797fa",
      },
      {
        activity: 1,
        img_url: "https://firebasestorage.googleapis.com/v0/b/asambe-2020.appspot.com/o/images%2Fimageline-9.jpg?alt=media&token=7042635d-0a89-40c9-9f71-16623d25aee4",
      },
      {
        activity: 1,
        img_url: "https://firebasestorage.googleapis.com/v0/b/asambe-2020.appspot.com/o/images%2Fimageline-18.jpg?alt=media&token=55f6aeb1-7a40-4474-a5ee-b5d96ddf4604",
      },
    ];

    this.setState({images: images});
  }

  displayImage(id) {
    // console.log(id)
    // var pk_details = this.state.images.filter(function (detail) {
    //   return detail.activity === id;
    // });

    // if(pk_details.length > 0) {
    //   return pk_details[0].img_url;
    // }

    return "https://firebasestorage.googleapis.com/v0/b/asambe-2020.appspot.com/o/images%2FM-IMG_2984.jpg?alt=media&token=57ef784c-1979-4e9b-9dde-832b3b984ac3";
  }

  openGuideView(event, id) {
    event.preventDefault();
    var tour = this.state.tours.find(function (tour) {
      if (tour.pk === id) {
        return tour;
      }
      return null;
    });

    var pk_details = this.state.images.filter(function (detail) {
      return detail.activity === id;
    });
  

    this.props.history.push({
      pathname: '/guide-view',
      state: {
         bookTour:  tour,
         images: pk_details,
      }
    })
  }

  handleChange(event) {
    var inputId = event.target.id;
    var inputValue = event.target.value;

    var search  = this.state.search;
    search[inputId] = inputValue;
    this.setState(search);
    
    Object.keys(search).forEach((key) => (search[key] === '') && delete search[key]);
    let params = new URLSearchParams(this.state.search).toString();
    // fetch(Config() + "/api/get_activity/?" + params)
    //   .then(res => res.json())
    //   .then(items => {
    //     this.setState({tours: items.data});
    // });

    var items = {
      
    }

    this.setState({tours: items.data});
  }

  showDiv(event) {
    var advanced = document.getElementById('advanced');

    if(event.type === "click") {
      if(!this.state.showAdvanced) {
        advanced.style.display = "block";
        this.setState({showAdvanced: true});
      } else {
        advanced.style.display = "none";
        this.setState({showAdvanced: false});
      }
    }
  }
  
  render() {

    let card = [];
    let locationsSelector = [];
    let tourTypeSelector = [];
    let vehicleTypeSelector = [];

    for (var i = 0; i < this.state.tours.length; i++) {
      let rating = this.state.tours[i].rating;
      let price = this.state.tours[i].price;
      let pk = this.state.tours[i].pk;

      card.push(
        <div key={"key-" + i} className="container mt-2 mb-2 pointer" onClick={(e) => this.openGuideView(e, pk)}>
          <div className="card w-100 guide_card" key={i}>
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={this.displayImage(pk)} className="card-img" width="100%" height="280px" alt="https://via.placeholder.com/150/"/>
              </div>
              <div className="col-md-7">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8">
                      <p className="card-title"><b>{this.state.tours[i].title}</b></p>
                    </div>
                    <div className="col-md-4 text-right">
                      {<Astronaut pk={pk} rating={rating}/>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <p className="card-text clamp-paragraph">{this.state.tours[i].description}</p>
                      <span className="fa fa-star"></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 offset-md-8 text-right">
                      <p><b>${price}</b></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    for (var j = 0; j < this.state.locations.length; j++) {
      locationsSelector.push(
        <option key={"locationsSelector-" + j}>{this.state.locations[j]}</option>
      );
    }

    for (var k = 0; k < this.state.tourTypes.length; k++) {
      tourTypeSelector.push(
        <option key={"tourTypeSelector-" + k}>{this.state.tourTypes[k]}</option>
      );
    }

    for (var l = 0; l < this.state.vehicleTypes.length; l++) {
      vehicleTypeSelector.push(
        <option key={"vehicleTypeSelector-" + l}>{this.state.vehicleTypes[l]}</option>
      );
    }

    return (
      <div>
        <section className="search">
          <div className="container search-padding">
            <div className="guide_card">
              <form>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <select className="form-control" id="location" onChange={this.handleChange} value={this.state.search.location}>
                            <option hidden>Location</option>
                            {locationsSelector}
                          </select>
                        </div>
                      </div>
                    </div> 
                  </div>
                  
                  <div id="advanced" className="row">
                    <div className="col-lg">
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <select className="form-control" id="tour_type" onChange={this.handleChange} value={this.state.search.tour_type}>
                            <option hidden>Tour Type</option>
                            {tourTypeSelector}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <select className="form-control" id="activity_duration" onChange={this.handleChange} value={this.state.search.activity_duration}>
                            <option hidden>Duration</option>
                            <option>less than 2hrs</option>
                            <option>more than 2hrs</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <select className="form-control" id="vehicle_type" onChange={this.handleChange} value={this.state.search.vehicle_type}>
                            <option hidden>Vehicle Types</option>
                            {vehicleTypeSelector}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-end mb-2">
                    <div className="col-lg-2 col-md-4 col-sm-6">
                      { this.state.showAdvanced
                        ? <input type="button" className="btn btn-dark-purple btn-block" value="Hide Advanced" onClick={this.showDiv.bind(this)} />
                        : <input type="button" className="btn btn-dark-purple btn-block" value="Advanced" onClick={this.showDiv.bind(this)} />
                      }
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

        {card}

        <Footer />
        <FooterBar />
      </div>
    );
  }
}
export default Guide;
