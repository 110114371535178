import React from 'react';
import './About.css';
import Nav from '../CustomNav/CustomNav'
import Footer from '../CustomFooter/CustomFooter'
import FooterBar from '../CustomFooterBar/CustomFooterBar'

class About extends React.Component {
  
  componentDidMount = () => {
    if (this.props.location){
      const element = document.getElementById(this.props.location.hash.replace("#", ""));
      if (element) element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }
  
  render() {
    return (
      <div>
        <section id="about" className="about-bg">
          <div className="container about-table">
            <div className="about-vertical">
              <h1 className="white-color text-center">WHAT’S ASAMBE ABOUT?</h1>
            </div>
          </div>
        </section>

        <section id="togetherness">
          <div className="text-center container pt-5 pb-5">
            <h1 className="purple-color pb-2">TOGETHER-NESS</h1>
            <p className="pb-2"><b className="purple-color">Let’s go together</b> is Asambe’s tagline and our core philosophy. 
              We intend for our service to be used in groups, a way in which like minded people can meet. 
              We aspire to create a sense of community, the feeling of being in a tribe.</p>
            <p className="">Through inviting <b className="purple-color">local entrepreneurs</b> to add vehicles to our fleet, 
              or to use our fleet to offer tours, Asambe is truly and fundamentally a collective. 
              Working hand in hand with locals helps us hyperlocalize our service and to always preserve the authenticity of an experience. </p>
          </div>
        </section>

        <section className="vibes">
          <div className="container inherit-height">
            <div className="row align-items-center inherit-height">
              <div className="col-md-6 inherit-height pt-2 afro">
              </div>
              <div className="col-md-6 text-center">
                <h1 className="white-color pb-2">EXPLORATION</h1>
                <p className="white-color"><b>It's an experience;</b></p>
                <p className="white-color pb-2"><b>The Spirit of Adventure!</b></p>
                <p className="dark-purple-color"><b>It's fun, it’s different, it's memorable, it’s quirky, it’s dope, it’s awesome, it's a vibe!</b></p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="text-center container pt-5 pb-5">
            <h1 className="purple-color pb-2">WOKE-NESS</h1>
            <p className="pb-2">We understand that the world needs to come together to solve the climate crisis and that every bit helps. 
              Our solar charged electric scooters and bicycles present a quiet, 
              environmentally friendly and ethical way to enjoy and preserve the natural environment.</p>
            <p>We are a Young <b className="purple-color">African</b> Start Up looking to change the (played out) “African story”, 
              optimistic about a greater future and we have bold ideas to make this a reality. </p>
          </div>
        </section>

        <section id="brand" className="cover_page">
          <div className="container text-center">
            <img className="afronaut" src={require('./resources/AFRONAUT.png')} alt="" />
          </div>
        </section>

        <section>
          <div className="container pt-5 pb-5">
            <div className="row pb-2">
              <div className="col-12 text-center">
                <h1 className="purple-color">WHAT’S THE BRAND ABOUT?</h1>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12 text-center">
                <p>The Asambe Brand is based on the genre of <b className="purple-color">Afrofuturism</b>, 
                  the reimagining of a future filled with arts, science and technology seen through an African lens. 
                  It's rewriting the narrative of Africa and imagining a future/reality filled not with poverty and despair but with hope, 
                  success and unparalleled technological advancement.</p>
              </div>
            </div>
            <div className="row align-items-center pb-2">
              <div className="col-md-6">
                <img className="about-image-width" src={require('./resources/ABOUT_LOGO.png')} alt="" />
              </div>
              <div className="col-md-6">
                <p>The logo, a spaceship and by extension the notion of Africans in space is an embodiment of Afrofuturism.</p>
                <p>Our logo seeks to evoke the emotions of the Asambe brand, the spirit of exploration, 
                  the thrill of adventures unknown and freedom with no bounds and  limitless opportunities. 
                  The analogy of space is carried through the Asambe brand language with the use of the “space purple” colour in its branding, 
                  with calling its depots stations and calling its consumers collectively <b className="purple-color">Afronauts</b>
                  ( a play on the word “<i>astronaut</i>” with the prefix “<i>Afro</i>” derived from “<i>Afrocentric</i>” or  ”<i>African.</i>”)</p>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12 text-center">
                <p>Asambe’s mascot carries the same name as its consumers. 
                  The term Afronaut was popularized by Edward Mukuka Nkoloso, 
                  a member of the Zambian resistance movement and the founder of the Zambia National Academy of Science, 
                  Space Research and Philosophy. He was responsible for Africa's first space program. 
                  The inspiration comes not from the success or failure of the program but rather from his courage and dare to dream, 
                  his resolve to see his project come to life, his Afrofuturism.</p>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12 text-center">
                <p className="purple-color"><b>“In a period when Africa is trying to get on its feet, i'm trying to give it wings.”</b></p>
                <p>E. Nkoloso</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <p>The Afronaut mascot carries an additional and equally important message. 
                  The Afronaut mascot is deliberately not specific to one particular gender, race, religion, nationality or sexual orientation. 
                  Anyone can be an Afronaut. This speaks to inclusion; it speaks to our core philosophy of Let's Go Together.</p>
              </div>
            </div>
          </div>
        </section>
        
        <section id="together" className="asambe">
          <div className="text-center">
            <img className="sustain" src={require('./resources/CSR.png')} alt="" />
          </div>
        </section>

        <section>
          <div className="container pt-5 pb-2">
            <div className="row pb-2">
              <div className="col-12 text-center">
                <h1 className="purple-color">THE TOGETHER INITIATIVE</h1>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12">
                <p>Let’s go together evolves from a philosophy to a tangible reality when we begin to include the millions of 
                  Africans at the bottom of the pyramid into the global smart mobility ecosystem. 
                  As part of our CSR Strategy “The Together Initiative”, Asambe will partner with communities (in particular young entrepreneurs) 
                  to set up a network of E-mobility hubs (colonies) in rural and peri-urban settlements across the continent.</p>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12">
                <p>Colonies will primarily offer communities ride sharing and delivery services. 
                  In addition, driven by local entrepreneurs and community needs the colonies may 
                  also provide other productive uses of power such as and not limited to:</p>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12">
                <p><b>Internet connectivity</b></p>
                <p><b>Financial services infrastructure (i.e ATMs)</b></p>
                <p><b>Cold storage</b></p>
                <p><b>Water supply</b></p>
                <p><b>Sanitation services and</b></p>
                <p><b>Electrical charging</b></p>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12">
                <p>Rather than imposing a (standardized) solution, 
                  colonies are a platform on which communities can design homegrown solutions to meet their particular needs. 
                  Each colony is unique as the look, feel and function is determined by and an expression of a community. 
                  Our vision for colonies goes beyond E-mobility hubs, it’s to create centres of (youth) innovation, arts and culture.</p>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12">
                <p>Colonies will be powered through a combination of solar PV and recycled 
                  Asambe Mobility lithium Ion batteries which are no longer fit for use in electric mobility 
                  but perfectly suited for stationary energy storage.</p>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12">
                <p>Colonies will have a great impact in communities through:</p>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12">
                <p><b>Displacing long walking trips (primarily children walking to school and; women and children collecting firewood and water),</b></p>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12">
                <p><b>Creating greater integration within and across communities, 
                  ultimately strengthening relationships and increasing access to opportunities, 
                  markets, services and products and,</b></p>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12">
                <p><b>Stimulating economic activity and subsequently reducing human and capital outflows.</b></p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <button className="btn button-style-2">DONATE NOW</button>
              </div>
            </div>
          </div>
          
        </section>

        <section id="support" >
          <div className="container pt-2 pb-2">
            <div className="row pb-2">
              <div className="col-12 text-center">
                <h1 className="purple-color">SUPPORT AND ACCOLADES</h1>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-md-6 text-center">
                <img src={require('./resources/SMW.png')} alt="" width="200" />
              </div>
              <div className="col-md-6 text-center">
                <img src={require('./resources/OM.png')} alt="" width="200" />
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-md-6 text-center">
                <img src={require('./resources/BED.png')} alt="" width="200" />
              </div>
              <div className="col-md-6 text-center">
                <img src={require('./resources/BC.png')} alt="" width="200" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-center">
                <p><b>Winner</b></p>
                <p className="purple-color"><b>SMART MOBILITY</b></p>
                <p className="purple-color"><b>WORLD SERIES PITCH 2020</b></p>
              </div>
              <div className="col-md-6 text-center">
                <p><b>Top 10 Finalist</b></p>
                <p className="purple-color"><b>VALUE CREATION</b></p>
                <p className="purple-color"><b>CHALLENGE 2019/2020</b></p>
              </div>
            </div>
          </div>
        </section>

        <section id="partners">
          <div className="container pb-5 pt-2">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="purple-color">OUR PARTNERS</h1>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-12 text-center">
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={require('./resources/MUNCH.png')} className=" w-10" height="120" width="120" alt="..."/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('./resources/PARIAH_STATE.png')} className=" w-10" height="120" width="120" alt="..."/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('./resources/SUPER_SOCO.png')} className=" w-10" height="120" width="120" alt="..."/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('./resources/ZTA.png')} className=" w-10" height="120" width="120" alt="..."/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('./resources/SAFARI.png')} className=" w-10" height="120" width="120" alt="..."/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('./resources/POWER_VENTURES.png')} className=" w-10" height="120" width="200" alt="..."/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('./resources/SUN.png')} className=" w-10" height="120" width="120" alt="..."/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="partners">
          <div className="container full-height">
            <div className="row pb-5 pt-5 full-height align-items-center">
              <div className="col-md-6 text-center white-color">
                <img src={require('./resources/PARTNER.png')}  width="100%" height="100%" alt="..."/>  
              </div>
              <div className="col-md-6 text-center white-color">
                <p>Want to add vehicles to our fleet?</p>
                <p>Want to offer guided tours using our vehicles?</p>
                <p><a className="white-color" href="/contact"><u>Contact us</u></a> to become a partner.</p>
              </div>
            </div>
          </div>
        </section>

        <Footer />

        <FooterBar />

      </div>  
    );
  }
}
export default About;