import React from 'react';
import './Admin.css';
import firebase from 'firebase'
import Config from '../Config';
import Images from './Images'

const storage = firebase.storage();

class Edit extends React.Component {
  
  constructor(props) {
    super(props);

    this.updatePost = this.updatePost.bind(this);
    this.deletePost = this.deletePost.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.setNewTour = this.setNewTour.bind(this);
    this.state = {
      tours: [],
      images: [],
    };
  }

  componentDidMount() {
    fetch(Config() + "/api/get_activity/")
    .then(res => res.json())
    .then(items => {
      console.log(items)
      this.setState({tours: items.data});
    });

    fetch(Config() + "/api/get_images/")
    .then(res => res.json())
    .then(items => {
      console.log(items)
      this.setState({images: items.data});
    });
  }

  handleChange(event) {
    var inputId = event.target.id;
    var inputValue = event.target.value;

    this.setNewTour(inputId, inputValue)
  }

  setNewTour(id, value) {
    var tour  = this.state.newTour;
    tour[id] = value;
    this.setState(tour);
  }

  handleUpdate(event) {
    var id = event.target.id.split("-")
    var inputId = id[0];
    var inputValue = event.target.value;

    var tours  = {...this.state.tours};
    tours[id[1]][inputId] = inputValue;
    this.setState(tours);
  }

  updatePost(event) {
    var id = event.target.id.split("-");

    if (id.length === 2) {
      fetch(Config() + "/api/patch_activity/", {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state.tours[id[1]])})
      .then(res => res.json())
      .then(items => {
        this.setState({tours: items.data});
      });
    }    
  }

  deletePost(event) {
    var id = event.target.id.split("-");
    var pk = this.state.tours[id[1]]['pk'];

    this.deleteImage(pk);
    
    if (id.length === 2) {
      fetch(Config() + "/api/delete_activity/", {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'pk': pk})})
      .then(res => res.json())
      .then(items => {
        this.setState({tours: items.data});
      });
    }
  }

  deleteImage(pk) {
    var pk_details = this.state.images.filter(function (detail) {
      return detail.activity === pk;
    });

    pk_details.forEach( function(detail, i) {
      var desertRef = storage.refFromURL(detail.img_url);

      desertRef.delete().then(function() {
        console.log("Image deleted")
      }).catch(function(error) {
        console.log(error)
      });
    });
  }
  
  render() {
    
    let card = [];

    for (var i = 0; i < this.state.tours.length; i++) {

      card.push(
        <div key={"card" + i} className="card guide_card">
          <div className="card-header" id={"heading" + i}>
            <h2 className="mb-0">
              <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target={"#id" + i} aria-expanded="false" aria-controls={"id" + i}>
                {this.state.tours[i].title}
              </button>
            </h2>
          </div>
          <div id={"id" + i} className="collapse" aria-labelledby={"heading" + i} data-parent="#accordionExample">
            <div className="card-body">
              <div className="container">
                <form>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor={"title-" + i}>Title</label>
                    </div>
                    <div className="col-md-9">
                      <input id={"title-" + i} value={this.state.tours[i].title} onChange={this.handleUpdate} type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="row mt-2">  
                    <div className="col-md-3">
                      <label htmlFor={"rating-" + i}>Rating</label>
                    </div>
                    <div className="col-md-9">
                      <input id={"rating-" + i} value={this.state.tours[i].rating} onChange={this.handleUpdate} type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor={"description-" + i}>Description</label>
                    </div>
                    <div className="col-md-9">
                      <textarea  id={"description-" + i} value={this.state.tours[i].description} onChange={this.handleUpdate} className="form-control" />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor={"price-" + i}>Price</label>
                    </div>
                    <div className="col-md-9">
                      <input id={"price-" + i} value={this.state.tours[i].price} onChange={this.handleUpdate} type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor={"location-" + i}>Location</label>
                    </div>
                    <div className="col-md-9">
                      <input id={"location-" + i} value={this.state.tours[i].location} onChange={this.handleUpdate} type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor={"activity_duration-" + i}>Duration</label>
                    </div>
                    <div className="col-md-9">
                      <input id={"activity_duration-" + i} value={this.state.tours[i].activity_duration} onChange={this.handleUpdate} type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor={"vehicle_type-" + i}>Vehicle Type</label>
                    </div>
                    <div className="col-md-9">
                      <input id={"vehicle_type-" + i} value={this.state.tours[i].vehicle_type} onChange={this.handleUpdate} type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor={"tour_type-" + i}>Tour Type</label>
                    </div>
                    <div className="col-md-9">
                      <input id={"tour_type-" + i} value={this.state.tours[i].tour_type} onChange={this.handleUpdate} type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="row mt-2">  
                    <div className="col-md-3">
                      <label htmlFor={"link-" + i}>External Link</label>
                    </div>
                    <div className="col-md-9">
                      <input id={"link-" + i} value={this.state.tours[i].link} onChange={this.handleUpdate} type="text" className="form-control" />
                    </div>
                  </div>
                  <Images img_details={this.state.images} pk={this.state.tours[i].pk} />
                  <div className="row mt-2 mb-2">  
                    <div className="col">
                      <input id={"submit-" + i} type="button" className="btn btn-outline-primary btn-block" value="update" onClick={this.updatePost} />
                    </div>
                    <div className="col">
                      <input id={"delete-" + i} type="button" className="btn btn-outline-danger btn-block" value="delete" onClick={this.deletePost} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }    

    return (
      <div>
        <section className="admin-bg pt-5">
          <div className="container pt-5 pb-2">
            <div className="accordion" id="accordionExample">
              {card}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Edit;
