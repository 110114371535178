import React from 'react';
import './Guide.css';
import Astronaut from './Astronaut'
import Nav from '../CustomNav/CustomNav'
import Footer from '../CustomFooter/CustomFooter'
import FooterBar from '../CustomFooterBar/CustomFooterBar'

class GuideView extends React.Component {

  constructor(props) {
    super(props);

    this.goToBook = this.goToBook.bind(this);

    this.state = {
      book: false,
      bookTour: props.location.state.bookTour,
      img_detail: props.location.state.images,
    }
  }

  goToBook() {
    this.props.history.push({
      pathname: '/book',
      state: { bookTour:  this.state.bookTour }
    })
  }

  render() {

    let slide = [];
    let indicator = [];
    let images = this.state.img_detail;

    for(var i=0; i<images.length; i++) {
      if(i===0) {
        slide.push(
          <div key={"img-" + i} className="carousel-item active show-height">
            <img src={images[i].img_url} className="d-block w-100" alt="https://via.placeholder.com/150/"/>
          </div>
        );
        
        indicator.push(
          <li key={"ind-" + i} data-target="#carouselExampleIndicators" data-slide-to={i} className="active"></li>
        )
      }
      else {
        slide.push(
          <div key={"img-" + i} className="carousel-item show-height">
            <img src={images[i].img_url} className="d-block w-100" alt="https://via.placeholder.com/150/"/>
          </div>
        );
        
        indicator.push(
          <li key={"ind-" + i} data-target="#carouselExampleIndicators" data-slide-to={i}></li>
        )
      }
    }

    return (
      <div>
        <Nav />

        <section className="cover-page" >
          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              {indicator}
            </ol>
            <div className="carousel-inner">
              {slide}
            </div>
            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </section>

        <section >
          <div className="container">
            <div className="row pb-5 pt-5">
              <div className="col-12 text-center">
                {<Astronaut fontSize="3em" pk={this.state.bookTour.pk} rating={this.state.bookTour.rating}/>}
              </div>
            </div>

            <div className="row pb-5 pt-5">
              <div className="col-12">
                <p className="include-breaks">{this.state.bookTour.description}</p>
              </div>
            </div>

            <div className="row pb-1 pt-1 text-center">
              <div className="col-12">
                <a className="purple-color purple-hover" href={this.state.bookTour.link}  target="_blank"  rel="noopener noreferrer"> <p>TOUR REVIEWS </p></a>
              </div>
            </div>

            <div className="row pb-5 pt-5">
              <div className="col-12 text-center">
                {/* <button className="btn button-style-2" onClick={this.goToBook}>BOOK NOW</button> */}
                <a href="javascript:void(Tawk_API.toggle())" className="btn button-style-2">BOOK NOW</a>
              </div>
            </div>
          </div>
        </section>

        <Footer />
        <FooterBar />
      </div>
    )
  }
}

export default GuideView;