import React from 'react';
import './Guide.css';
import Nav from '../CustomNav/CustomNav'
import Footer from '../CustomFooter/CustomFooter'
import FooterBar from '../CustomFooterBar/CustomFooterBar'
import Config from '../Config';

class Book extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.sendEmail =this.sendEmail.bind(this);
    this.reset =this.reset.bind(this);
    this.state = {
      guide: props.location.state? props.location.state.bookTour: "",
      loader: false,
      fields: {
        name: '',
        email: '',
        contact: '',
        tickets: 0,
        booking_date: '',
        activity_id: props.location.state? props.location.state.bookTour.pk: "",
      },
    };
  }

  reset() {
    var initialState = {
      guide: '',
      fields: {
        name: '',
        email: '',
        contact: '',
        tickets: 0,
        booking_date: '',
        activity_id: '',
      },
    };
    this.setState(initialState);
  }

  handleChange(event) {
    var inputId = event.target.id;
    var inputValue = event.target.value;

    var state  = this.state.fields;
    state[inputId] = inputValue;
    this.setState(state);

  }

  sendEmail(event) {
    this.setState({loader: true});
    fetch(Config() + "/api/send_enquiry_email/", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state.fields)})
      .then(res => {
        if(res.ok) {
          console.log('email sent')
          this.reset()
          this.props.history.push({
            pathname: '/guide',
          })
      }});
  }

  render() {
    return (
      <div>

        <Nav />

        <div className="container">
          {this.state.loader?
          <div className="loader"></div>:
          null}
        </div>

        <section className="contact_bg">
          <div className="container full-height">
            <div className="row pt-5 pb-5 full-height align-items-center">
              <div className="col-md-6">
                <div className="row text-center align-items-center full-height">
                  <div className="col-12">
                    <img src={require('./resources/ASAMBE_LOGO.png')} alt="" width="50%" height="100%" />
                  </div>
                  <div className="col-12">
                    <h1 className="white-color">DISCOVER WHAT</h1> 
                    <h1 className="white-color">LIES BEYOND.</h1>
                  </div>  
                </div>
              </div>
              <div className="col-md-6">
                <form>
                  <div className="form-group pt-3">
                    <input id="tour-name" type="text" className="form-control" placeholder="Tour Name" value={this.state.guide.title} readOnly />
                  </div>
                  <div className="form-group">
                    <input id="name" type="text" className="form-control" placeholder="Name" onChange={this.handleChange} value={this.state.fields.name}/>
                  </div>
                  <div className="form-group">
                    <input id="email" type="email" className="form-control" placeholder="Email" onChange={this.handleChange} value={this.state.fields.email}/>
                  </div>
                  <div className="form-group">
                    <input id="contact" type="text" className="form-control" placeholder="Contact Number" onChange={this.handleChange} value={this.state.fields.contact}/>
                  </div>
                  <div className="form-group">
                    <select className="form-control" id="tickets" onChange={this.handleChange} value={this.state.fields.tickets}>
                      <option hidden>Tickets</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input id="booking_date" type="date" className="form-control" onChange={this.handleChange} value={this.state.fields.date} />
                  </div>
                  <input type="button" className="btn button-style-2 btn-block" value="BOOK" onClick={this.sendEmail.bind(this)} />
                </form>
              </div>
            </div>
          </div>
        </section>

        <Footer />

        <FooterBar />

      </div>
    );
  }
}
export default Book;