import React from 'react';
import './Admin.css';
import Nav from '../CustomNav/CustomNav'
import Add from './Add'
import Edit from './Edit'
import Coordinates from './Coordinates'


class Admin extends React.Component {
  
  constructor(props) {
    super(props);

    this.showEdit = this.showEdit.bind(this);
    this.showAdd = this.showAdd.bind(this);
    this.showCoordinates = this.showCoordinates.bind(this);

    this.state = {
      show: 'add',
    };
  }

  showAdd(event) {
    this.setState({show: 'add'})
  }

  showCoordinates(event) {
    this.setState({show: 'mapping'})
  }

  showEdit(event) {
    this.setState({show: 'edit'})
  }
  
  render() {  

    return (
      <div>
        
        <Nav showEdit={this.showEdit} showCoordinates={this.showCoordinates} showAdd={this.showAdd}/>

        {this.state.show === 'add'?
          <Add showEdit={this.showEdit} />:
          null}

        {this.state.show === 'edit'?
          <Edit/>:
          null}

        {this.state.show === 'mapping'?
          <Coordinates/>:
          null}    

      </div>
    );
  }
}
export default Admin;
