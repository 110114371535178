import React from 'react';
import './Contact.css';
import Footer from '../CustomFooter/CustomFooter'
import FooterBar from '../CustomFooterBar/CustomFooterBar'
import Config from '../Config';

class Contact extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.sendEmail =this.sendEmail.bind(this);
    this.reset =this.reset.bind(this);
    this.state = {
      loader: false,
      mail: {
        name: '',
        subject: '',
        email: '',
        contact: '',
        message: '',
      },
    };
  }

  reset() {
    var initialState = {
      loader: false,
      mail: {
        name: '',
        subject: '',
        email: '',
        contact: '',
        message: '',
      },
    };
    this.setState(initialState);
  }

  handleChange(event) {
    var inputId = event.target.id;
    var inputValue = event.target.value;

    var state  = this.state.mail;
    state[inputId] = inputValue;
    this.setState(state);

  }

  sendEmail(event) {
    this.setState({loader: true});
    fetch(Config() + "/api/send_contact_email/", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state.mail)})
      .then(res => {
        if(res.ok) {
          console.log('email sent')
          this.reset();
          this.props.history.push({
            pathname: '/',
          })
      }});;
  }

  render() {
    return (
      <div>
        <div className="container">
          {this.state.loader?
          <div className="loader"></div>:
          null}
        </div>

        <section className="contact_bg">
          <div className="container full-height">
            <div className="row pt-5 pb-5 full-height align-items-center">
              <div className="col-md-6">
                <div className="row text-center align-items-center full-height">
                  <div className="col-12">
                    <img src={require('./resources/ASAMBE_LOGO.png')} alt="" width="50%" height="100%" />
                  </div>
                  <div className="col-12">
                    <h1 className="white-color">DISCOVER WHAT</h1> 
                    <h1 className="white-color">LIES BEYOND.</h1>
                  </div>  
                </div>
              </div>
              <div className="col-md-6">
                {/* <form>
                  <div className="form-group pt-3">
                    <input id="name" type="text" className="form-control" placeholder="Name" onChange={this.handleChange} value={this.state.mail.name}/>
                  </div>
                  <div className="form-group">
                    <input id="subject" type="text" className="form-control" placeholder="Subject" onChange={this.handleChange} value={this.state.mail.subject}/>
                  </div>
                  <div className="form-group">
                    <input id="email" type="email" className="form-control" placeholder="Email" onChange={this.handleChange} value={this.state.mail.email}/>
                  </div>
                  <div className="form-group">
                    <input id="contact" type="text" className="form-control" placeholder="Contact Number" onChange={this.handleChange} value={this.state.mail.contact}/>
                  </div>
                  <div className="form-group">
                    <textarea id="message" className="form-control" rows="6" placeholder="Message" onChange={this.handleChange} value={this.state.mail.message}></textarea>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <input type="button" className="btn button-style-2 btn-block" value="SEND" onClick={this.sendEmail.bind(this)} />
                    </div>
                  </div>
                </form> */}
                <div className="card bg-transparent text-white card_border">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <h1 className="card-title">LET'S GO</h1>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h1 className="card-title"><b id="flash-alt">GREEN.</b></h1>
                      </div>
                    </div> 
                    <div className="row">
                      <div className="col">
                        <p className="card-text"><b>We are here for you</b>, feel free to reach out to us.</p>
                      </div>
                    </div>    
                    <div className="row pt-2">
                      <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 pt-2">
                        <a href="javascript:void(Tawk_API.toggle())" className="btn btn-block button-style mr-2 mt-2">LETS CHAT</a>
                      </div>       
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />

        <FooterBar />

      </div>
    );
  }
}
export default Contact;