import React from 'react';
import './Exploration.css';
import { Map, GoogleApiWrapper } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '50vh'
};

class GoogleMap extends React.Component {

  render() {

    const {center, markers} = this.props;

    if(Object.keys(center).length > 0) {
      return (
        <Map
          google={this.props.google}
          zoom={4}
          style={mapStyles}
          initialCenter={center}
        >
          {markers}
        </Map>
      )
    }
    else {
      return null;
    }
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCVlkF3BGtCWa1D1qpBqCdThxvQOHvQOM8'
})(GoogleMap);