import React from 'react';
import './Home.css';
import { Link } from "react-router-dom";

class Home extends React.Component {

  componentDidMount() {
    var blink_speed = 2000;
    var wordArray = ['EXPLORE.', 'TOGETHER.', 'GREEN.'];
    var count=0;

    setInterval(function () {
      var ele = document.getElementById('flash');
      if(ele != null){
        ele.innerHTML = wordArray[count++];

        if(count===wordArray.length) {
          count=0;
        }
      }
    }, blink_speed);
  }

  render() {
    return (
      <div>
        <div className="home_bg"></div>

        <div className="container full-screen">
          <div className="row align-items-center inherit-height">
            <div className="col-lg-7 col-md-8 col-sm-10">
              <div className="card bg-transparent-landing text-white card_border">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h1 className="card-title">LET'S GO</h1>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <h1 className="card-title"><b id="flash">GREEN.</b></h1>
                    </div>
                  </div> 
                  <div className="row">
                    <div className="col">
                      <p className="card-text"><b>Zulu for Let’s Go</b>, Asambe is a clean, fun and cost effective way to explore a town or city.</p>
                    </div>
                  </div>    
                  <div className="row pt-2">
                    <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 pt-2">
                      <Link className="btn btn-block button-style mr-2 mt-2" to="/exploration">SELF EXPLORATION</Link>
                    </div>
                    <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 pt-2">
                      <Link className="btn btn-block button-style-2 mr-2 mt-2" to="/guide">GUIDED EXPERIENCES</Link>
                    </div>        
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
