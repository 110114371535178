import React from 'react';
import './Admin.css';
import firebase from 'firebase';
import Config from '../Config';


const storage = firebase.storage();

class Add extends React.Component {
  
  constructor(props) {
    super(props);

    this.savePost = this.savePost.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.setNewTour = this.setNewTour.bind(this);
    this.state = {
      newTour: {
        activity: { 
          title: '',
          rating: '',
          price: '',
          description: '',
          location: '',
          activity_duration: '',
          vehicle_type: '',
          tour_type: '',
          link: '',
        },
        img_urls: [],
      },
      tours: [],
      files: [],
    };
  }

  handleChange(event) {
    var inputId = event.target.id;
    var inputValue = event.target.value;

    this.setNewTour(inputId, inputValue)
  }

  setNewTour(id, value) {
    var tour  = this.state.newTour.activity;
    tour[id] = value;
    this.setState(tour);
  }

  setImageUrl(id, value) {
    var tour  = this.state.newTour;
    tour[id] = value;
    this.setState(tour);
  }

  handleFileSelect(event) {
    var inputId = event.target.id;
    var fileName = document.getElementById(inputId).files[0].name;
    var nextSibling = event.target.nextElementSibling
    nextSibling.innerText = fileName
    this.setState({files: [...this.state.files, event.target.files[0]]})
  }

  async savePost(event) {
    var files = this.state.files;
    var img_urls = []

    for(var i = 0; i < this.state.files.length; i++) {
      var file = await this.uploadImage(files[i]);
      img_urls.push(file);
    }
    this.setImageUrl('img_urls', img_urls)

    fetch(Config() + "/api/post_activity/", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state.newTour)})
      .then(res => {
        if(res.ok) {
          this.props.showEdit();
      }})
      .catch((error) => {
        console.log(error)
      });
  }

  uploadImage(file) {
    return new Promise(function (resolve, reject) {
      var uploadTask = storage.ref('images/'+file.name).put(file);

      uploadTask.on('state_changed', function(snapshot){
        
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
          default:
            console.log('');

        }
      }, function(error) {
        console.log(error);
      }, function() {
        console.log("success");
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          resolve(downloadURL);
        });
      });
    });
  }
  
  render() {

    let files = [];

    for (var i = -1; i < this.state.files.length; i++) {
      files.push(
        <div key={i} className="custom-file mt-2">
          <input type="file" className="custom-file-input" id={"file-" + i} onChange={this.handleFileSelect}/>
          <label className="custom-file-label" htmlFor={"file-" + i}>Choose file</label>
        </div>
      )
    }

    return (
      <div>
        <section className="admin-bg">
          <div className="container search-padding">
            <div className="guide_card">
              <div className="container">
                <form>
                  <div className="row mt-2">
                    <div className="col">
                      <input id="title" value={this.state.newTour.title} onChange={this.handleChange} type="text" className="form-control" placeholder="Title" />
                    </div>
                  </div>
                  <div className="row mt-2">  
                    <div className="col">
                      <input id="rating" value={this.state.newTour.rating} onChange={this.handleChange} type="text" className="form-control" placeholder="Rating (default value, will be replaced)" />
                    </div>
                  </div>
                  <div className="row mt-2">  
                    <div className="col">
                      <textarea  id="description" value={this.state.newTour.description} onChange={this.handleChange} className="form-control" placeholder="Description" rows="5" />
                    </div>
                  </div>
                  <div className="row mt-2">  
                    <div className="col">
                      <input id="price" value={this.state.newTour.price} onChange={this.handleChange} type="text" className="form-control" placeholder="Price" />
                    </div>
                  </div>
                  <div className="row mt-2">  
                    <div className="col">
                      <input id="location" value={this.state.newTour.location} onChange={this.handleChange} type="text" className="form-control" placeholder="Location" />
                    </div>
                  </div>
                  <div className="row mt-2">  
                    <div className="col">
                      <input id="activity_duration" value={this.state.newTour.activity_duration} onChange={this.handleChange} type="text" className="form-control" placeholder="Length of activity (In hours)" />
                    </div>
                  </div>
                  <div className="row mt-2">  
                    <div className="col">
                      <input id="vehicle_type" value={this.state.newTour.vehicle_type} onChange={this.handleChange} type="text" className="form-control" placeholder="Type of vehicle" />
                    </div>
                  </div>
                  <div className="row mt-2">  
                    <div className="col">
                      <input id="tour_type" value={this.state.newTour.tour_type} onChange={this.handleChange} type="text" className="form-control" placeholder="Type of Tour" />
                    </div>
                  </div>
                  <div className="row mt-2">  
                    <div className="col">
                      <input id="link" value={this.state.newTour.link} onChange={this.handleChange} type="text" className="form-control" placeholder="Trip advisor link (A link starts with https or http)" />
                    </div>
                  </div>
                  {files}
                  <div className="row mt-2 mb-2">  
                    <div className="col">
                      <input type="button" className="btn button-style-2 btn-block" value="Save" onClick={this.savePost} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Add;
