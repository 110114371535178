import React from 'react';
import './Admin.css';

function Images(props) {
  
  let inputFields = []
  var details = props.img_details; 
  var pk = props.pk

  var pk_details = details.filter(function (detail) {
    return detail.activity === pk;
  });

  for (var x = 0; x < pk_details.length; x++) {
    
    inputFields.push(
      <div key={x} className="row mt-2">  
        <div className="col-md-3">
          <label htmlFor={"link-" + x}>Image URL</label>
        </div>
        <div className="col-md-9">
          <input id={"link-" + x} value={pk_details[x].img_url} type="text" className="form-control" disabled />
        </div>
      </div>
    );

  }

  return (
    inputFields
  )
}

export default Images;