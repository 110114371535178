import React from 'react';
import './CustomFooterBar.css';

function CustomFooterBar() {
  return (
    <section className="footer-bar-bg">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <p className="white-color fine-print pt-3">&copy; 2020 Asambe, All rights reserved</p>
          </div>
        </div>
      </div>
    </section>
  )   
}

export default CustomFooterBar;