import React, {useContext, useState} from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap'
import { NavLink, Link } from "react-router-dom";
import './CustomNav.css';
import {firebaseAuth} from '../provider/AuthProvider'

function CustomNav(props) {
    
    const [expanded, setExpanded] = useState(false);
    
    const { token, handleSignout } = useContext(firebaseAuth)

    const handleSubmit = async (e) => {
        e.preventDefault()
        await handleSignout()
    }

    const handleClick = event => {
        document.getElementById("drop").firstChild.classList.remove('show');
        document.getElementById("drops").setAttribute("aria-expanded","false");
        if(document.querySelector('[aria-labelledby="drops"]') != null) {
            document.querySelector('[aria-labelledby="drops"]').classList.remove('show');
        }
    }

    return (
        <Navbar expanded={expanded} fixed="top" expand="lg">
            <div className="container">
                <Navbar.Brand><NavLink to="/" onClick={() => setExpanded(expanded ? false : false)}><img src={require('./resources/LOGO.png')} width="80%" height="30" alt="" loading="lazy"/></NavLink></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse className="justify-content-end">
                    <Nav id="drop" className="desktop" onClick={handleClick}>
                        <NavDropdown id="drops" alignRight className="btn button-style no-padding no-boarder mx-2" title="DISCOVER">
                            <Link className="dropdown-item" to="/about#about">About Us</Link>
                            <Link className="dropdown-item" to="/contact">Contact Us</Link>
                            <Link className="dropdown-item" to="/about#support">Support and Partners</Link>
                            <Link className="dropdown-item" to="/about#together">The Together Initiative</Link>                            
                            <a className="dropdown-item" href="https://www.asambe.shop/">Shop</a>                            
                            {token === null? 
                            <Link className="dropdown-item" to="/auth">Sign In</Link>: 
                            <Link className="dropdown-item" to="/" onClick={handleSubmit}>Sign Out</Link>}
                        </NavDropdown>

                        {token === null? 
                            <div/>:
                            <NavDropdown alignRight className="btn button-style no-padding no-boarder mx-2" title="ADMIN">
                                <NavDropdown.Item id="add" onClick={props.showAdd}>Add Activity</NavDropdown.Item>
                                <NavDropdown.Item id="edit" onClick={props.showEdit}>Edit Activity</NavDropdown.Item>
                                <NavDropdown.Item id="mapping" onClick={props.showCoordinates}>Coordinates</NavDropdown.Item>
                                <Link className="dropdown-item" to="/" onClick={handleSubmit}>Sign Out</Link>
                            </NavDropdown>
                        }
                    </Nav>
                    <Nav className="mobi" onClick={() => setExpanded(expanded ? false : "expanded")}>
                            <Link className="dropdown-item" to="/about#about">About Us</Link>
                            <Link className="dropdown-item" to="/contact">Contact Us</Link>
                            <Link className="dropdown-item" to="/about#support">Support and Partners</Link>
                            <Link className="dropdown-item" to="/about#together">The Together Initiative</Link>                            
                            <Link className="dropdown-item" href="https://www.asambe.shop/">Shop</Link>                            
                            {token === null? 
                            <Link className="dropdown-item" to="/auth">Sign In</Link>: 
                            <Link className="dropdown-item" to="/" onClick={handleSubmit}>Sign Out</Link>}
                        

                        {token === null? 
                            <div/>:
                            <>
                                <Link id="add" onClick={props.showAdd}>Add Activity</Link>
                                <Link id="edit" onClick={props.showEdit}>Edit Activity</Link>
                                <Link id="mapping" onClick={props.showCoordinates}>Coordinates</Link>
                                <Link className="dropdown-item" to="/" onClick={handleSubmit}>Sign Out</Link>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    )   
}

export default CustomNav;