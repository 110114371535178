import React from 'react';
import './Admin.css';
import Config from '../Config';


class Add extends React.Component {
  
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
    this.setNewCoordinates = this.setNewCoordinates.bind(this);
    this.saveCoordinates = this.saveCoordinates.bind(this);
    this.deleteCoordinates = this.deleteCoordinates.bind(this);

    this.state = {
      allCoordinates: [],
      newCoordinates: {
        central: false, 
        latitude: '',
        longitude: '',
      }
    };
  }

  componentDidMount() {
    fetch(Config() + "/api/get_coordinates/")
      .then(res => res.json())
      .then(items => {
        this.setState({allCoordinates: items.data});
    });
  }
  
  handleChange(event) {
    var inputId = event.target.id;
    var inputValue = event.target.value;

    this.setNewCoordinates(inputId, inputValue)
  }

  handleChangeCheckBox(event) {
    var inputId = event.target.id;
    var inputValue = this.state.newCoordinates.central ? false : true;

    this.setNewCoordinates(inputId, inputValue)
  }

  setNewCoordinates(id, value) {
    var coordinates  = this.state.newCoordinates;
    coordinates[id] = value;
    this.setState(coordinates);
  }

  saveCoordinates(event) {
    fetch(Config() + "/api/post_coordinates/", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state.newCoordinates)})
      .then(res => res.json())
      .then(items => {
        this.setState({allCoordinates: items.data});
        this.setState({ 
          newCoordinates: {
            central: false, 
            latitude: '',
            longitude: '',
          }});
      });
  }

  deleteCoordinates(event) {
    var id = event.target.id.split("-");
    var pk = this.state.allCoordinates[id[1]]['pk'];
    
    if (id.length === 2) {
      fetch(Config() + "/api/delete_coordinates/", {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'pk': pk})})
      .then(res => res.json())
      .then(items => {
        this.setState({allCoordinates: items.data});
      });
    }
  }

  render() {  

    let rows = [];

    for (var i = 0; i < this.state.allCoordinates.length; i++) {
      let current_row = this.state.allCoordinates[i];
      
      rows.push(
        <tr key={i}>
          <th scope="row">{current_row.pk}</th>
          <td>{current_row.latitude}</td>
          <td>{current_row.longitude}</td>
          <td>{current_row.central? "true": "false"}</td>
          <td><input id={"btn-" + i} type="button" className="btn btn-danger btn-block" value="Delete" onClick={this.deleteCoordinates} /></td>
        </tr>
      );
    }

    return (
      <div className="admin-bg">
        <section >
          <div className="container search-padding">
            <div className="row">
              <div className="col-md-3">
                <input id="latitude" value={this.state.newCoordinates.latitude} onChange={this.handleChange} type="text" className="form-control" placeholder="Latitude" />
              </div>
              <div className="col-md-3">
                <input id="longitude" value={this.state.newCoordinates.longitude} onChange={this.handleChange} type="text" className="form-control" placeholder="Longitude" />
              </div>
              <div className="col-md-3">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="central" checked={this.state.newCoordinates.central} onChange={this.handleChangeCheckBox} />
                  <label className="form-check-label" htmlFor="central">Central</label>
                </div>
              </div>
              <div className="col-md-3">
                <input type="button" className="btn button-style-2 btn-block" value="Save" onClick={this.saveCoordinates} />
              </div>
            </div>
          </div>  
        </section>
        <section >
          <div className="container">
            <div className="row">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Latitude</th>
                    <th scope="col">Longitude</th>
                    <th scope="col">Central</th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Add;
