import React , {useContext} from 'react';
import {Route, Switch} from 'react-router-dom';
import {firebaseAuth} from '../provider/AuthProvider'
import './App.css';
import Home from '../home/Home';
import Landing from '../landing/Landing';
import SignIn from '../auth/Auth';
import Guide from '../guide/Guide';
import GuideView from '../guide/GuideView';
import Book from '../guide/Book';
import Admin from '../admin/Admin';
import About from '../about/About';
import Contact from '../contact/Contact';
import Exploration from '../exploration/Exploration';
import Logistics from '../logistics/Logistics';
import {TransitionGroup, CSSTransition} from "react-transition-group";

function App() {
  const { token } = useContext(firebaseAuth);

  return (
    <Route render={({location}) => {
      return (
			<TransitionGroup>
				<CSSTransition key={location.key} timeout={450} classNames="fade">
          <Switch location={location}>
            <Route path="/" component={Landing} exact />
            <Route path="/ride" component={Home} exact />
            <Route path="/guide" component={Guide} />
            <Route path="/guide-view" component={GuideView} />
            <Route path="/book" component={Book} />
            <Route path="/admin" component={token === null ? SignIn : Admin } />
            <Route path="/auth" component={token === null ? SignIn : Admin } />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/exploration" component={Exploration} />
            <Route path="/logistics" component={Logistics} />
          </Switch>
        </CSSTransition>
			</TransitionGroup>
    )}} />
  )
}

export default App;