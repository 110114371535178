import React from 'react';
import './Guide.css';
import { faUserAstronaut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Astronaut(props) {
  
  let icons = []

  for (var x = 0; x < 5; x++) {
    if(x < props.rating) {
      icons.push(<FontAwesomeIcon style={{fontSize:props.fontSize}} key={props.pk + "-x-" + x} icon={faUserAstronaut} className="checked" />);
    } 
    else {
      icons.push(<FontAwesomeIcon style={{fontSize:props.fontSize}} key={props.pk + "-x-" + x} icon={faUserAstronaut} className="not-checked" />);
    }
  }

  return (
    icons
  )
}

export default Astronaut;