import './Auth.css';
import Nav from '../CustomNav/CustomNav'
import React, {useContext} from 'react';
import {firebaseAuth} from '../provider/AuthProvider'

const Auth = (props) => {

  const {handleSignin, inputs, setInputs} = useContext(firebaseAuth)
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    await handleSignin()
  }

  const handleChange = e => {
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
  }

  return (
    <div>
      
      <div className="auth_bg"></div>

      <Nav />

      <div>
        <div id="login" className="padding_top">
          <div className="container">
            <div id="login-row" className="row justify-content-center">
              <div id="login-column" className="col-md-6">
                <div id="login-box" className="col-md-12">
                  <form onSubmit={handleSubmit}>
                    <h3 className="text-center white-color">Login</h3>
                    <div className="form-group">
                      <label htmlFor="username" className="white-color">email:</label><br/>
                      <input type="email" name="email" className="form-control" value={inputs.email} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="white-color">Password:</label><br/>
                      <input type="password" name="password" className="form-control" value={inputs.password} onChange={handleChange} />
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <input type="submit" className="btn button-style-3" value="Sign In" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;